export const PATH_ICON_HISTORY = 'assets/images/account/history/icon-detail'
export const LABEL = {
  INFO_TABLE: '',
  DEPOSIT_NOW: 'Nạp ngay',
  DETAIL_TRANSACTIONS: 'Chi tiết giao dịch',
  CONTACT: 'Trò chuyện với CSKH',
  COPY: 'Copy',
  COPYED: 'Đã copy',
  TIME: 'Thời gian',
  TRANSACTIONS_CODE: 'Mã giao dịch',
  METHOD: 'Phương thức',
  DEPOSIT: 'Nạp',
  WITHDRAW: 'Rút',
  BANK: 'Ngân hàng',
  SENDER: 'Người gửi',
  AMOUNT_MONEY: 'Số tiền',
  REMITTANCE_CONTENT: 'Nội dung chuyển tiền',
  NOTES: 'Ghi chú',
  AMOUNT_REWARD: 'Số tiền thưởng',
  AMOUNT_WITHDRAW: 'Số tiền',
  BANK_NUMBER: 'Số tài khoản',
  MONEY: 'tiền qua',
  UNSUCCESSFUL: 'Không thành công',
  CHECKED: 'Kiểm tra',
  RATE_WITHDRAW: 'Tỷ giá',
  AMOUNT_CRYPTO: 'Lượng tiền ảo',
  RATE_CRYPTO: 'Tỷ giá',
  AMOUNT_RECEIVE: 'Số tiền nhận',
  AMOUNT_RECEIVE_WITHDRAW_CRYPTO: 'Số tiền rút',
  RECEIVING_WALLET: 'Ví nhận',
  TYPE_CRYPTOPAY: 'Loại tiền ảo',
  DEPOSIT_WITHDRAW: 'Nạp/Rút',
  HOME_NETWORK: 'Nhà mạng',
  ID_CARD: 'Mã thẻ',
  SERI_NUMBER: 'Số seri',
  AMOUNT_BANK: 'Số tiền nạp',
  CARD_FEE: 'Phí gạch thẻ',
  ACTUALLY_RECEIVED: 'Thực nhận',
  ACCOUNT_HOLDER: 'Tới tài khoản',
  PHONE: 'Số điện thoại',
  STATUS: 'Trạng thái',
  CANCEL_PROMOTION: 'Hủy khuyến mãi',
  TYPE_CARD: 'Loại thẻ',
  AMOUNT: 'Số lượng',
  SEE_MORE: '[Xem thẻ]',
  CARD: 'Chi tiết thẻ cào',
  HISTORY_TRANSACTIONS: 'Lịch sử giao dịch',
  HISTORY_BETS: 'Lịch sử cá cược',
  DETAIL_BETS: 'Chi tiết cá cược'
}
export const SELECT_TRANSACTIONS = [
  {
    id: 1,
    value: 'all',
    text: 'Tất cả'
  },
  {
    id: 2,
    value: 'deposit',
    text: 'Nạp tiền'
  },
  {
    id: 3,
    value: 'withdraw',
    text: 'Rút tiền'
  }
]
export const HEADING_TABLE = ['Hình thức', 'Loại', 'Thời gian', 'Số tiền', 'Trạng thái']

export type MethodKey = 'phone_card' | 'ibanking' | 'momo' | 'smartpay' | 'senpay' | 'codepay' | 'bank_account' | 'cryptopay' | 'nicepay' | 'ewallet_banking' | 'p2p';

export const METHODS: Record<MethodKey, string> = {
  phone_card: 'Thẻ cào',
  ibanking: 'Ngân hàng',
  momo: 'Momo',
  smartpay: 'Paywin',
  senpay: 'Senpay',
  codepay: 'Codepay',
  bank_account: 'Ngân hàng',
  cryptopay: 'Tiền ảo',
  nicepay: 'CodePay',
  ewallet_banking: 'CodePay2',
  p2p: 'P2P'
}

export const ACTION_TITLE = {
  DEPOSIT: 'Nạp qua',
  WITHDRAW: 'Rút qua',
  PROMOTION: 'Cộng tiền thưởng',
  COMMISSION: 'Trả thưởng'
}

export const TYPE_TITLE = {
  DEPOSIT: 'Nạp',
  WITHDRAW: 'Rút',
  PROMOTION: 'Khuyến mãi',
  COMMISSION: 'Hoàn trả',
  PROMOTION_CANCEL: 'Hủy khuyến mãi'
}

export const TRANSACTIONS = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  FINISHED: 'FINISHED',
  CANCEL: 'CANCEL',
  PROMOTION: 'PROMOTION',
  PROMOTION_CANCEL: 'PROMOTION_CANCEL',
  COMMISSION: 'COMMISSION'
}

export const STATUS_TRANSACTION = {
  FINISHED: 'Thành công',
  CANCEL: 'Thất bại',
  PROCESSING: 'Đang xử lý'
}

export const EMPTY_DATA = {
  icon: '/assets/images/components/desktop/pages/account/history/empty.webp',
  text: 'Không tìm thấy kết quả giao dịch nào gần đây.'
}
export const TYPE_FIELD_DETAIL = {
  STATUS: 'status',
  CODE: 'code',
  AMOUNT: 'amount',
  WALLET: 'wallet',
  WALLET_RECEIVED: 'wallet-received',
  CHECKED: 'checked',
  MATCH_CODE: 'match-code'
}
export const KEY_EMIT = {
  MODAL_DETAIL_CARD: 'modal-detail-card',
  CANCEL_TRANSACTION: 'cancel-transaction'
}
export const KEY_EMIT_INIT_MODAL_DETAIL = 'init-modal-detail'

export const EMIT_DATA_MODAL = {
  emitActionButton: KEY_EMIT.CANCEL_TRANSACTION
}

export const MESSAGE = {
  ERROR: 'Đã có lỗi xảy ra'
}

export const DATA_TOOLTIP = {
  subText: 'Lịch sử giao dịch đã được mã hóa nhằm tăng tính bảo mật. Vui lòng liên lệ CSKH 24/7 nếu cần hỗ trợ.'
}

export const DATA_TOOLTIP_BET = {
  heading: 'Lịch sử cá cược của bạn trong vòng 7 ngày gần nhất.',
  subText: 'Lịch sử cá cược đã được mã hóa nhằm tăng tính bảo mật. Vui lòng liên lệ CSKH 24/7 nếu cần hỗ trợ.'
}

export const PACKAGES = [
  {
    name: 'Khuyến mãi 150% giá trị nạp',
    description: 'Thưởng nạp lần đầu',
    id: 5
  },
  {
    name: 'Khuyến mãi 118% giá trị nạp',
    description: 'Thưởng nạp lần đầu',
    id: 2
  },
  {
    name: 'Hoàn trả vô tận 1.25%',
    description: 'Hoàn trả 1.25%',
    id: 1
  }
]
